import React, { Fragment, useEffect } from "react";
import { getImageUrl } from "@/utils";
import { useRouter } from "next/router";

const NotFound = () => {
    const router = useRouter();

    useEffect(() => {
        const query = router.query;

        if (query.redirected_from_404) {
            // Don't initiate redirect loop if already redirected
            return;
        }

        const pathname = window.location.pathname;

        router.push({
            pathname,
            query: {
                ...query,
                redirected_from_404: true,
            },
        });
    }, [router]);

    if (!router.query.redirected_from_404) {
        return <Fragment />;
    }

    return (
        <div className="h-screen bg-blueGray-100">
            <div className="mx-5 my-8 flex flex-col items-center justify-center gap-6 bg-white p-4 sm:flex-row">
                <img src={getImageUrl("/images/404.png")} alt="" width={400} />
                <div className="text-center">
                    <p className="mb-8 text-5xl">PAGE NOT FOUND</p>
                    <p className="mb-4">
                        We couldn't find {`https://mockat.com${router.asPath}`}
                    </p>
                    <button
                        onClick={() => {
                            router.push("https://mockat.com");
                        }}
                        className="rounded-full bg-yellow-8 px-4 py-2"
                    >
                        Back To Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;